import "./index.css";
import {  Navbar , Slider, Heading, About } from './components';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css'
import Gallery from'./pages/Gallery'


const App = () => {
  return (
    <div className="App">
      <Heading />
      <div className="gradient__bg">
          <Navbar />
            <Routes>
              <Route path="/gallery" element={<Gallery/>} />
            </Routes>
      </div>
      <div className='snow'></div>
      <div className='snow2'></div>
      <div className='winafe__title_container'></div>
      <div className='winafe__subtitle_container'>The legendary end-of-semester party</div>

      <div className='winafe__btn-landingpage-container'>
        <a className='winafe__btn-landingpage-link'>
          21.12.2023<br></br>
          LochNess Hönggerberg <br></br>
          (HXE)
          </a>
      </div>
      <a href="https://helfertool.vseth.ethz.ch/winafe23/" class="blinking_btn">Become a helper!</a>
        < About />
      <div className='SlideContainer'>
      < Slider />
    </div>
    </div>
    
  )
}

export default App

