import React from 'react'
import { useState } from 'react';
import './about.css'
import {Switch} from "antd";


const AboutCard = () => {

    const [isGerman, setIsGerman] = useState(false);

    const toggleLanguage = () => {
        setIsGerman(!isGerman);
    };

    const getTranslatedText = () => {
        if (isGerman) {
        // Return German text
        return (
            <>
            <p>Wenn die Schneeflocken tanzen, die Sterne hell erstrahlen und der Glühweingeschmack in der Luft liegt - Dann ist es Zeit! Zeit für das zauberhafteste Fest, das Winternachtsfest am Hönggerberg! Am Donnerstag, 21. Dezember 2023, pünktlich um 20:00, versammeln sich alle festlichen Seelen, um die märchenhafteste Nacht des Jahres gebührend zu feiern.</p>
            <br></br>
            <p>Nur TWINT und Bargeld werden akzeptiert, Kartenzahlung nicht möglich!</p>
            <br></br>
            <p>Nachtbusse bis 3:40 werden zur Verfügung gestellt Garderobe für 2CHF (teuerer für Taschen grösser als Ersti Bags)</p>
            </>
        );
        } else {
        // Return English text
        return (
            <>
            <p>When the snowflakes are dancing, the stars are shining brightly and the taste of mulled wine is in the air - then it's time! Time for the most magical festival, the Winter Night Festival on Hönggerberg! On Thursday, December 21, 2023, punctually at 20:00, all festive souls will gather to celebrate the most magical night of the year in style.</p>
            <br></br>
            <p>Only TWINT and cash are accepted, card payment not possible!</p>
            <br></br>
            <p>Night buses until 3:40 are provided Cloakroom for 2CHF (more expensive for bags larger than Ersti Bags)</p>
            </>
        );
        }
    };

    return (
        
        <div className='winafe__info_container'>
            <div className='switcher'><Switch onClick={toggleLanguage} autoFocus="true"/></div>
        <svg width="100%" height="800" viewBox="0 0 915 920" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d_517_28)">
            <path d="M0 98C0 43.8761 43.8761 0 98 0H787C841.124 0 885 43.8761 885 98V816C885 870.124 841.124 914 787 914H98C43.8761 914 0 870.124 0 816V98Z" fill="white"/>
          </g>
          <foreignObject width="100%" height="100%" >
            <div xmlns="http://www.w3.org/1999/xhtml" className="winafe__info_title">
              <p>About</p>
              
            </div>
            
          </foreignObject>
          <foreignObject width="100%" height="100%">
            <div xmlns="http://www.w3.org/1999/xhtml" className="winafe__info_text">
              {getTranslatedText()}
            </div>
          </foreignObject>
          <defs>
            <filter id="filter0_d_517_28" x="0" y="0" width="915" height="945" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dx="25" dy="26"/>
              <feGaussianBlur stdDeviation="2.5"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_517_28"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_517_28" result="shape"/>
            </filter>
          </defs>
        </svg>
        </div>
    );
};

export default AboutCard;
