import React from 'react'
import { Helmet } from 'react-helmet'

const Heading = () => {
  return (
    <div>
      <Helmet>
        <title>WiNaFe 2023</title>
        <meta name="description" content="Wenn die Schneeflocken tanzen, die Sterne hell erstrahlen und der Glühweingeschmack in der Luft liegt - Dann ist es Zeit! Zeit für das zauberhafteste Fest, das Winternachtsfest am Hönggerberg!" />
      </Helmet>
    </div>
  )
}

export default Heading
