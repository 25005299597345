import SimpleImageSlider from "react-simple-image-slider";
import useResizeObserver from "use-resize-observer";
import './slider.css';

// Create a context for the images in the specified folder
const imageContext = require.context('../../assets/impress/', false, /\.(png|jpe?g|svg)$/);

// Get all image file names in the folder
const imageFileNames = imageContext.keys();

// Map the file names to the corresponding image URLs
const images = imageFileNames.map((fileName) => ({
  url: imageContext(fileName),
}));

const Slider = () => {
  const { ref, width = 1, height = 1 } = useResizeObserver();

  return (
    <div className="Slider">
      <div ref={ref} className="card_imgBox">
        <SimpleImageSlider
          className="card_img"
          width={width}
          height={height}
          images={images}
          showBullets={false}
          showNavs={true}
        />
      </div>
    </div>
  );
};

export default Slider;
