import '../App.css'
import '../index.css'

export default function Gallery(){
    return(
        <>
        <h2>Gallery</h2>
        
        </>
    )
}
