import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLin, RiCactusLine} from 'react-icons/ri'
import { Link } from 'react-router-dom'; // Import Link
import './navbar.css';
import logo from '../../assets/logo__winafe__white.png'

const Menu = () => (
  <>
  <p><a href='#home'>Home</a></p>
  <p><Link to='/gallery'>Gallery</Link></p>
  <p><a href='mailto:quaestur@winafe.ethz.ch'>Contact</a></p>
  </>
)

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  return (
    <div className='winafe__navbar'>
     <div className='winafe__navbar-links'>
        <div className='winafe__navbar-links_logo'>
          <img src={logo} alt='logo' />
        </div>
        <div className='winafe__navbar-links_container'>
          <Menu />
        </div>
      </div>
      <div className='winafe__navbar'></div>
      <div className='winafe__navbar-menu'>
        {toggleMenu 
        ? <RiCactusLine color='#fff' size={27} onClick={() => setToggleMenu(false)} />
        : <RiMenu3Line color='#fff' size={27} onClick={() => setToggleMenu(true)} />
      }
      {toggleMenu && (
        <div className='winafe__navbar-menu_container scale-up-center'>
          <div className='winafe__navbar-menu_links'>
          <Menu />
          </div>
        </div>
      )}
      </div>
    </div>
  )
}

export default Navbar
